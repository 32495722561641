import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { IOfficeTicker } from 'src/app/interfaces/offices/office-ticker';
import { TickerMonthService } from 'src/app/services/ticker-month.service';

@Component({
  selector: 'ast-ticker-month',
  templateUrl: './ticker-month.component.html',
  styleUrls: ['./ticker-month.component.scss']
})
export class TickerMonthComponent implements OnInit, OnDestroy {

  private readonly size: number = 2;

  private dataEventSubscription = null;
  private updateEventSubscription = null;

  public data: IOfficeTicker[];
  public columns: number = 1;

  public isLoading:boolean = false;
  
  constructor(
    public tickerService: TickerMonthService,
    private breakpointObserver: BreakpointObserver
  ) { 
    breakpointObserver.observe([
      Breakpoints.HandsetPortrait
    ]).subscribe(result => {
      if (result.matches) {
        this.columns = 2;
      }
    });

    breakpointObserver.observe([
      Breakpoints.HandsetLandscape,
      Breakpoints.TabletLandscape,
      Breakpoints.TabletPortrait,
      Breakpoints.WebPortrait
    ]).subscribe(result => {
      if (result.matches) {
        this.columns = 2;
      }
    });

    breakpointObserver.observe([
      Breakpoints.WebLandscape
    ]).subscribe(result => {
      if (result.matches) {
        this.columns = 2;
      }
    });
  }

  ngOnInit(): void {
    
    this.isLoading = true;
    
    this.dataEventSubscription = this.tickerService.dataRecievedEvent.subscribe(() => {
      var length = this.tickerService.data.length < this.size ? this.tickerService.data.length : this.size;
      
      this.data = this.tickerService.data.sort((a, b) => b.monthlySalesCount - a.monthlySalesCount).slice(0, length);
      
      
      this.isLoading = false;
    });

    this.updateEventSubscription = this.tickerService.updateRecievedEvent.subscribe(() => {
      var length = this.tickerService.data.length < this.size ? this.tickerService.data.length : this.size;
      this.data = this.tickerService.data.sort((a, b) => b.monthlySalesCount - a.monthlySalesCount).slice(0, length);
    });

    this.tickerService.startConnection();
    this.tickerService.addTickerListener();
  }

  ngOnDestroy(): void {
    if (this.dataEventSubscription) {
      this.dataEventSubscription.unsubscribe();
    }
    if (this.updateEventSubscription) {
      this.updateEventSubscription.unsubscribe();
    }
    this.tickerService.stopConnection();
  }

}
