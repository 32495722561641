import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { ISource } from 'src/app/interfaces/sources/source';
import { AddSaleModel } from 'src/app/models/sales/add-sale-model';
import { SourceService } from 'src/app/services/source.service';
import { SalesService } from 'src/app/services/sales.service';
import { StateService } from 'src/app/services/state.service';
import { IBadRequest } from 'src/app/interfaces/bad-request';
import { IState } from 'src/app/interfaces/states/state';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { SpendPopupComponent } from '../spend-popup/spend-popup.component';
import { SpendsService } from 'src/app/services/spends.serice';
import { interval, timer } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'ast-office',
  templateUrl: './office.component.html',
  styleUrls: ['./office.component.scss']
})
export class OfficeComponent implements OnInit {

  private model: AddSaleModel = new AddSaleModel();

  public addForm: FormGroup;

  public userName: string = '';
  public officeName: string = '';

  public salesToday: number = 0;
  public salesAll: number;
  public isLoading: boolean = false;

  public states: IState[] = [];
  public sources: ISource[] = [];

  public badRequest: IBadRequest;
  private dialogRef:any;

  constructor(
    private router: Router,
    private dialog:MatDialog,
    private authService: AuthService,
    private sourceService: SourceService,
    private salesService: SalesService,
    private stateService: StateService,
    private spendsService:SpendsService
  ) { }

  ngOnInit(): void {
    this.userName = this.authService.getName();
    this.officeName = this.authService.getOffice()

    this.initForm();

    this.getPopupFlag();

    this.getSalesCount();
    this.getStates();
    this.getSources();
    this.initPopupTimer();
  }

  public showSales() {
    let url = 'office-sales';
    window.open(url, "_blank");
  }


  private getPopupFlag():void {
    
    this.badRequest = null;
    var officeId = this.authService.getOfficeId();
    this.spendsService.showPopup(officeId).subscribe(
      result => {
       
        if(result)
          this.showPopup();

        if (this.badRequest) {
          this.badRequest = null;
        }
      },
      error => {
        this.badRequest = error.error;
       
      },
      () => {
       
      }
    );
  }

  private initPopupTimer():void {
    timer(1000, 60*60*1000).subscribe(() => {
      
      const currentDate = new Date();
      
      let day = currentDate.getDay();
      let hours = currentDate.getHours();


      if(day != 1 || hours > 6)
        return;
      
      this.getPopupFlag();
    })

  }

  private showPopup():void {
    if (this.dialogRef) 
      return;
    
    this.dialogRef = this.dialog.open(SpendPopupComponent, {
      disableClose: true,
      width: '800px',
      height: '250px',
      data: {officeId: this.authService.getOfficeId()},
    });

    this.dialogRef.afterClosed().subscribe(() => {
      this.dialogRef = undefined;
    });

    
  }

  private initForm() {
    this.addForm = new FormGroup({
      'phone': new FormControl(null,
        [
          this.phoneValidator,
          Validators.required
        ]),
      'email': new FormControl(null, [
        Validators.required,
        Validators.email
      ]),
      'state': new FormControl(null, Validators.required),
      'source': new FormControl(null, Validators.required),
      'office': new FormControl(this.authService.getOfficeId(), Validators.required),
    });
  }

  private phoneValidator(control: FormControl) {
    var regex = new RegExp(/\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/i);
    let valid = regex.test(control.value);
    return  valid ? null : {"phone": control.value};
  }

  

  private getSalesCount() {
    this.startLoading();
    this.badRequest = null;
    var officeId = this.authService.getOfficeId();
    this.salesService.countToday(officeId).subscribe(
      result => {
        this.salesToday = result;

        if (this.badRequest) {
          this.badRequest = null;
        }
      },
      error => {
        this.badRequest = error.error;
        this.stopLoading();
      },
      () => {
        this.stopLoading();
      }
    );

    this.salesService.countAll(officeId).subscribe(
      result => {
        this.salesAll = result;

        if (this.badRequest) {
          this.badRequest = null;
        }
      },
      error => {
        this.badRequest = error.error;
        this.stopLoading();
      },
      () => {
        this.stopLoading();
      }
    );
  }

  private getSources() {
    this.startLoading();
    this.badRequest = null;

    this.sourceService.getByOffice(this.authService.getOfficeId()).subscribe(
      result => {
        this.sources = [...result];

        if (this.badRequest) {
          this.badRequest = null;
        }
      },
      error => {
        this.badRequest = error.error;
        this.stopLoading();
      },
      () => {
        this.stopLoading();
      }
    );
  }



  private getStates() {
    this.startLoading();
    this.badRequest = null;

    this.stateService.getByOffice(this.authService.getOfficeId()).subscribe(
      result => {
        this.states = [...result];

        if (this.badRequest) {
          this.badRequest = null;
        }
      },
      error => {
        this.badRequest = error.error;
        this.stopLoading();
      },
      () => {
        this.stopLoading();
      }
    );
  }

  get phone() { return this.addForm.get('phone'); }
  get email() { return this.addForm.get('email'); }
  get state() { return this.addForm.get('state'); }
  get source() { return this.addForm.get('source'); }
  get office() { return this.addForm.get('office'); }

  private cleanForm() {
    this.addForm.reset();
    this.office.setValue(this.authService.getOfficeId());
    //this.router.navigate(['']);
  }

  private startLoading() {
    this.isLoading = true;
    this.addForm.disable();
  }

  private stopLoading() {
    this.isLoading = false;
    this.addForm.enable();
  }

  add(): void {
    if (this.addForm.invalid) {
      return;
    }

    this.badRequest = null;
    this.startLoading();
    this.model.populateFromFormGroup(this.addForm);

    this.salesService.add(this.model).subscribe(
      result => {
        if (this.badRequest) {
          this.badRequest = null;
        }
        this.getSalesCount();
      },
      error => {
        this.stopLoading();
        if(error.status == 406)
        {
          this.showPopup();
          return;
        }

        this.badRequest = error.error;
        
      },
      () => {
        this.stopLoading();
        this.cleanForm();
      }
    );
  }

}
