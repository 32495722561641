import { Component, OnInit, OnDestroy } from '@angular/core';

import { IOfficeTicker } from 'src/app/interfaces/offices/office-ticker';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { TickerService } from 'src/app/services/ticker.service';
import { IOfficeTickerData } from 'src/app/interfaces/offices/office-ticker-data';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'ast-ticker',
  templateUrl: './ticker.component.html',
  styleUrls: ['./ticker.component.scss']
})
export class TickerComponent implements OnInit, OnDestroy {

  private readonly size: number = 16;

  private dataEventSubscription = null;
  private updateEventSubscription = null;

  public total:number = 0;

  public viewControl = new FormControl('daily');


  public tickerData: IOfficeTicker[];
  public data: IOfficeTickerData = {
    salesPerHour: 0,
    mostDealThisHour: "",
    topSourceThisHour: "",
    salesPerHourState: 0,
    tickerData: [],
    sources: [],
    states: [],
    topSourcesMonth: [],
    topSourcesWeek: []
  };


  public columns: number = 4;
  //public perHourClassName:string = 'per-hour-none';
  public isLoading:boolean = false;

  constructor(
    public tickerService: TickerService,
    private breakpointObserver: BreakpointObserver
  ) { 
    breakpointObserver.observe([
      Breakpoints.HandsetPortrait
    ]).subscribe(result => {
      if (result.matches) {
        this.columns = 2;
      }
    });

    breakpointObserver.observe([
      Breakpoints.HandsetLandscape,
      Breakpoints.TabletLandscape,
      Breakpoints.TabletPortrait,
      Breakpoints.WebPortrait
    ]).subscribe(result => {
      if (result.matches) {
        this.columns = 3;
      }
    });

    breakpointObserver.observe([
      Breakpoints.WebLandscape
    ]).subscribe(result => {
      if (result.matches) {
        this.columns = 4;
      }
    });
  }


  public chatFormat(value:number):string
  {
    if (value % 1 === 0) {
      return value.toString();
    } else {
      return '';
    }

  }

  public getPerHourClass():string {
    if(this.data)
    {
      switch(this.data.salesPerHourState)
      {
        case 0:
          return 'per-hour-none';
        case 1:
          return 'per-hour-up'
        case 2:
          return 'per-hour-down'
        default: return '';
      }
    }
  }

  ngOnInit(): void {
    this.isLoading = true;
    console.log(screen.width);
    console.log(window.innerWidth);
    this.viewControl.disable();

    

    this.view = screen.width > 650 ? undefined : [screen.width,0];
    this.showXAxisLabel = screen.width > 650;
    this.showYAxisLabel = screen.width > 650;
    
    this.dataEventSubscription = this.tickerService.dataRecievedEvent.subscribe(() => {
      this.setTickerData();
      this.data = this.tickerService.data;
      this.isLoading = false;
      this.viewControl.enable();
    });
    
    this.updateEventSubscription = this.tickerService.updateRecievedEvent.subscribe(() => {
      this.setTickerData();
      this.data = this.tickerService.data;

    });

    this.tickerService.startConnection();
    this.tickerService.addTickerListener();

    this.viewControl.valueChanges.subscribe(val => {
      this.setTickerData();
    })
  }

  private setTickerData() {
      let view = this.viewControl.value;
      var length = this.tickerService.data.tickerData.length < this.size ? this.tickerService.data.tickerData.length : this.size;
      
      switch(view)
      {
        case "daily": {
            this.tickerData = this.tickerService.data.tickerData
            .sort((a, b) => b.dailySalesCount - a.dailySalesCount)
            .slice(0, length);

            this.tickerData.forEach(element => {
              element.sales = element.dailySalesCount
            });

            break;
          }

          case "weekly": {
            this.tickerData = this.tickerService.data.tickerData
            .sort((a, b) => b.weeklySalesCount - a.weeklySalesCount)
            .slice(0, length);

            this.tickerData.forEach(element => {
              element.sales = element.weeklySalesCount
            });
            break;
          }

          case "monthly": {
            this.tickerData = this.tickerService.data.tickerData
            .sort((a, b) => b.monthlySalesCount - a.monthlySalesCount)
            .slice(0, length);

            this.tickerData.forEach(element => {
              element.sales = element.monthlySalesCount
            });
            break;
          }

        
      }

      this.total = this.data.tickerData.reduce((sum, current) => sum + current.sales, 0);

  }

  ngOnDestroy(): void {
    if (this.dataEventSubscription) {
      this.dataEventSubscription.unsubscribe();
    }
    if (this.updateEventSubscription) {
      this.updateEventSubscription.unsubscribe();
    }
    this.tickerService.stopConnection();
  }

 
 // width:number = screen.width > 650 ? 0 : screen.width;
  //view: any[] = [this.width, 0];
  view:any[];

  

  // options for the chart
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = false;
  showXAxisLabel = false;
  xAxisLabel = 'Sources';
  showYAxisLabel = false;
  yAxisLabel = 'Sales';
  timeline = true;

 
  //pie
  showLabels = true;

  

}
