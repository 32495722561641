<mat-toolbar color="primary" class="mat-elevation-z4">
  <div class="header-logo-container">
    <img class="header-logo" src="assets/logo.svg" routerLink="/" />
  </div>
  <span class="fill-remaining-space"></span>
  <ng-container *ngIf="authService.isSignedIn()">
    <ng-container *ngIf="showLogin()">
      <button mat-flat-button color="primary"
        [matMenuTriggerFor]="accountActionsMenu">{{authService.getName()}}</button>
      <mat-menu #accountActionsMenu="matMenu" xPosition="before">
        <div class="mat-hint mat-menu-label">{{authService.getEmail()}}</div>
        <mat-divider></mat-divider>
        <button mat-menu-item (click)="authService.signout()">Sign Out</button>
      </mat-menu>
    </ng-container>
    <ng-container *ngIf="!showLogin()">
      <button mat-flat-button color="primary" (click)="authService.signout()">Sign Out</button>
    </ng-container>
  </ng-container>
</mat-toolbar>

<router-outlet></router-outlet>