import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

import { AuthService } from './auth/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'ast-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  public title: string;

  constructor(
    public authService: AuthService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.title = environment.projectName;
  }

  public showLogin() {
    let curUrl = this.router.url;
    if(curUrl.includes('ticker'))
    {
      return false;
    }

    return true;
  }
}
